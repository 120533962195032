@import "../theme/mixins";

$brand: 'epi';
$brandAssets: "/assets/#{$brand}";



@font-face {
  font-family: AppFont;
  src: url("../assets/fonts/Nootype\ -\ Radikal.otf");
}
@font-face {
  font-family: AppFontBold;
  src: url("../assets/fonts/Nootype\ -\ Radikal\ Bold.otf");
}
@font-face {
  font-family: AppFontMedium;
  src: url("../assets/fonts/Nootype\ -\ Radikal\ Medium.otf");
}
@font-face {
  font-family: AppFontLight;
  src: url("../assets/fonts/Nootype\ -\ Radikal\ Light.otf");
}

:root {
  --brand: $brand;
	--ion-color-primary: #1E429C;;
	--ion-color-primary-rgb: 30,66,156;
	--ion-color-primary-contrast: #ffffff;
	--ion-color-primary-contrast-rgb: 255,255,255;
	--ion-color-primary-shade: #1a3a89;
	--ion-color-primary-tint: #3555a6;

	--ion-color-secondary: #B0063E;
	--ion-color-secondary-rgb: 176,6,62;
	--ion-color-secondary-contrast: #ffffff;
	--ion-color-secondary-contrast-rgb: 255,255,255;
	--ion-color-secondary-shade: #9b0537;
	--ion-color-secondary-tint: #b81f51;

	--ion-color-tertiary: #FD5E00;
	--ion-color-tertiary-rgb: 253,94,0;
	--ion-color-tertiary-contrast: #000000;
	--ion-color-tertiary-contrast-rgb: 0,0,0;
	--ion-color-tertiary-shade: #df5300;
	--ion-color-tertiary-tint: #fd6e1a;

  --ion-color-success: #2dd36f;
  --ion-color-success-rgb: 45, 211, 111;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #28ba62;
  --ion-color-success-tint: #42d77d;

  --ion-color-warning: #ffc409;
  --ion-color-warning-rgb: 255, 196, 9;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0ac08;
  --ion-color-warning-tint: #ffca22;

  --ion-color-danger: #eb445a;
  --ion-color-danger-rgb: 235, 68, 90;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #cf3c4f;
  --ion-color-danger-tint: #ed576b;

  --ion-color-dark: #66605a;
  --ion-color-dark-rgb: 102,96,90;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255,255,255;
  --ion-color-dark-shade: #5a544f;
  --ion-color-dark-tint: #75706b;

  --ion-color-medium:  #808080;
	--ion-color-medium-rgb: 128,128,128;
	--ion-color-medium-contrast: #000000;
	--ion-color-medium-contrast-rgb: 0,0,0;
	--ion-color-medium-shade: #717171;
	--ion-color-medium-tint: #8d8d8d;

  --ion-color-light: #B0B0B0;
	--ion-color-light-rgb: 176,176,176;
	--ion-color-light-contrast: #000000;
	--ion-color-light-contrast-rgb: 0,0,0;
	--ion-color-light-shade: #9b9b9b;
	--ion-color-light-tint: #b8b8b8;

  --ion-font-family: AppFontBold, sans-serif;
  --ion-margin: 12px;
  --ion-padding: 12px;
}

body {
  font-family: AppFont, sans-serif;
  color: var(--ion-color-black);
}

h1,h2,h3,h4,h5,h6 {
  font-family: AppFontBold, Helvetica, sans-serif;
  color: var(--color-h);
  margin: 0;
}
p,span {
  font-family: AppFont, sans-serif;
  color: var(--color-p-span);
  margin: 0;
}

app-email-action,
app-sign-in,
app-sign-in-order,
app-sign-up,
app-maintenance-page,
app-email-confirmation,
app-checkout-modal,
app-payment-modal,
app-impressum,
app-tos,
app-my-orders,
app-privacy {
  background: #fff;
  background-size: cover;
  ion-header {
    background: transparent;
  }
  ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
  h3 {
    &:first-child {
      margin-top: 0px;
    }
    font-size: 16px !important;
    line-height: 20px !important;

    color: #231F20 !important;
    margin-bottom: 15px !important;
  }
  
  p {
    font-family: AppFontLight, sans-serif !important;
    font-size: 14px !important;
    line-height: 23px !important;
    color: #8A8A8A !important;
    margin-bottom: 20px;
  }
}

app-home,
app-menu-modal {
  background: var(--ion-color-primary) !important;

  ion-content {
        background: transparent !important;
          --background: transparent !important;
  }
  ion-footer {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-payment-success {
  background: white !important;
  ion-footer {
    background: transparent !important;
    --background: transparent !important;
  }
   ion-content {
    background: transparent !important;
    --background: transparent !important;
  }
}
app-menu {
  ion-header {
    background: transparent;
  }
  ion-content {
    background: transparent;
    --background: transparent !important;
  }
}
app-scan-qr {
  background: #4A5257;
}
